import React from "react"
import Scrollspy from "react-scrollspy"
import { Navbar, Nav } from "react-bootstrap"
import Scroller from "./scroller"
export default class Header extends React.Component {
  constructor(props) {
    super(props)
    Scroller.handleAnchorScroll = Scroller.handleAnchorScroll.bind(this)
  }

  render() {
    return (
      <>
        <Navbar
          className="navbar navbar-expand-lg navbar-light fixed-top py-3"
          id="mainNav"
          expand="lg"
          collapseOnSelect={true}
        >
          <div className="container">
            <a
              className="navbar-brand js-scroll-trigger"
              href="#page-top"
              onClick={Scroller.handleAnchorScroll}
            >
              Anthony A. Nozzolillo, Esq.
            </a>
            <Navbar.Toggle aria-controls="navbarResponsive" />
            <Navbar.Collapse id="navbarResponsive">
              <Nav className="navbar-nav ml-auto my-2 my-lg-0">
                <Scrollspy
                  className="navbar-nav"
                  items={[
                    "portfolio",
                    "about",
                    "videos",
                    "services",
                    "contact",
                  ]}
                  currentClassName="active"
                  rootEl={"#mainNav"}
                  offset={-75}
                >
                  <li className="nav-item">
                    <Nav.Link
                      className={"js-scroll-trigger"}
                      href="#portfolio"
                      onClick={Scroller.handleAnchorScroll}
                    >
                      Accomplished
                    </Nav.Link>
                  </li>
                  {/* <li className="nav-item">
                    <Nav.Link
                      className={"js-scroll-trigger"}
                      href="#reviews"
                      onClick={Scroller.handleAnchorScroll}
                    >
                      Recognized
                    </Nav.Link>
                  </li> */}
                  <li className="nav-item">
                    <Nav.Link
                      className={"js-scroll-trigger"}
                      href="#about"
                      onClick={Scroller.handleAnchorScroll}
                    >
                      Trusted
                    </Nav.Link>
                  </li>
                  <li className="nav-item">
                    <Nav.Link
                      className={"js-scroll-trigger"}
                      href="#videos"
                      onClick={Scroller.handleAnchorScroll}
                    >
                      Knowledgeable
                    </Nav.Link>
                  </li>
                  <li className="nav-item">
                    <Nav.Link
                      className={"js-scroll-trigger"}
                      href="#services"
                      onClick={Scroller.handleAnchorScroll}
                    >
                      Professional
                    </Nav.Link>
                  </li>
                  <li className="nav-item">
                    <Nav.Link
                      className={"js-scroll-trigger"}
                      href="#contact"
                      onClick={Scroller.handleAnchorScroll}
                    >
                      Contact
                    </Nav.Link>
                  </li>
                  <li className="nav-item">
                    <Nav.Link
                      target="_blank"
                      href="https://www.avvo.com/attorneys/11566-ny-anthony-nozzolillo-993256.html"
                      rel="noreferrer"
                    >
                      <img
                        alt="Avvo - Rate your Lawyer. Get Free Legal Advice."
                        id="avvo_badge"
                        src="//images.avvo.com/avvo/cms/images/amos_assets/microbadge.png"
                      />
                    </Nav.Link>
                  </li>
                </Scrollspy>
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </>
    )
  }
}
