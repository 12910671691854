import React from "react"
import Scrollspy from "react-scrollspy"
import { Navbar, Nav } from "react-bootstrap"
import Scroller from "./scroller"
import { StaticImage } from "gatsby-plugin-image"

const Hero = () => {
  return (
    <header className="masthead">
      <div className="container h-100">
        <div className="row h-100 align-items-end justify-content-center text-center">
          <div className="col-lg-10 align-self-end">
            <StaticImage
              src="../images/nozzolillo-logo-trans.png"
              alt="Anthony A. Nozzolillo, Esq. logo"
              placeholder="blurred"
              width={200}
              height={200}
            />
            <br />
            <br />
            <h1 className="text-uppercase text-black font-weight-bold">
              Seasoned New York State Real Estate Law &
              <br />
              General Practice Attorney
            </h1>
            <hr className="divider my-4" />
          </div>
          <div className="col-lg-12 align-self-baseline">
            <p className="text-black font-weight-bold mb-5">
              Anthony A. Nozzolillo, Esq. is a seasoned General Practice
              Attorney with a mainstay concentration in Real Estate Law, where
              he represents individual and corporate buyers, sellers, and
              lenders in both routine and complex residential and commercial
              real estate transactions. In addition to Real Estate, Mr.
              Nozzolillo’s Practice consists of Civil Litigation, Estate
              Planning, Contract Law, Business Law and Corporate Law.
            </p>
            <a
              className="btn btn-dark btn-xl js-scroll-trigger"
              href="#portfolio"
              onClick={Scroller.handleAnchorScroll}
            >
              Learn More
            </a>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Hero
